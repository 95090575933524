import { Input } from "@/components/ui/input";
import { Button, buttonVariants } from "@/components/ui/button";
import { actions } from "astro:actions";
import type { User } from "lucia";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { navigate } from "astro:transitions/client";

type FormValues = {
  spreadsheetURL: string;
};

export const CreateSyncForm = ({
  user,
  defaultValues,
}: {
  user: User | null;
  defaultValues?: FormValues & { id: string };
}) => {
  const form = useForm<FormValues>({ defaultValues });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          console.log("Creating sync...");

          const result = await actions.createSync(values);

          if (result.error) {
            alert(result.error.message);
          } else {
            navigate("/");
          }
        })}
        className="flex flex-col gap-8"
      >
        <FormItem>
          <FormLabel>
            1.{" "}
            {user
              ? "You have already connected your Garmin account"
              : "Connect your Garmin account"}
          </FormLabel>
          <FormControl>
            <a
              className={cn(buttonVariants(), "flex w-fit")}
              href="/garmin/login"
            >
              {user ? "Re-s" : "S"}ign in with Garmin
            </a>
          </FormControl>
          {!user && (
            <FormDescription>
              Connect your Garmin account securely to Relo
            </FormDescription>
          )}
        </FormItem>
        <FormField
          name="spreadsheetURL"
          control={form.control}
          rules={{
            required: true,
            pattern: /^https:\/\/docs\.google\.com\/spreadsheets\/d\//,
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>2. Google Sheet URL</FormLabel>
              <FormControl>
                <Input
                  placeholder="e.g. https://docs.google.com/spreadsheets/d/18a4YXK8vawRlfSoN4yNHdXZQT2vecOCApyuSCrhLoBM/edit?gid=0#gid=0"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                This is the URL where your Garmin data will be synced to
              </FormDescription>
            </FormItem>
          )}
        />
        <div className="flex justify-end gap-2">
          {defaultValues && (
            <Button
              type="button"
              variant="link"
              onClick={async () => {
                const result = await actions.deleteSync({
                  syncId: defaultValues.id,
                });

                if (result.error) {
                  alert(result.error.message);
                } else {
                  navigate("/");
                }
              }}
            >
              Delete sync
            </Button>
          )}

          <Button
            type="submit"
            className="flex"
            disabled={
              !user ||
              !form.formState.isValid ||
              form.formState.isSubmitted ||
              form.formState.isSubmitting
            }
          >
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
};
