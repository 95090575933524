import { type ClassValue, clsx } from "clsx";
import { ResultAsync } from "neverthrow";
import { twMerge } from "tailwind-merge";
import { AppError, createErrorFactory } from "./error";
import { z } from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function safeParse<T extends z.ZodType>(
  type: T,
  value: unknown,
): ResultAsync<z.infer<T>, AppError> {
  return ResultAsync.fromPromise(
    type.parseAsync(value),
    createErrorFactory("Failed to parse object"),
  );
}

export function fn<T extends z.ZodType, R, E>(
  type: T,
  fn: (value: z.infer<T>) => ResultAsync<R, E>,
): (value: unknown) => ResultAsync<R, E | AppError> {
  const safeParse = ResultAsync.fromThrowable(
    type.parseAsync,
    createErrorFactory("Failed to parse function parameter"),
  );

  return (value: unknown) => {
    return safeParse(value).andThen(fn);
  };
}
